/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'

import { useSession } from '@tofu/apps/shop/providers/session'

import { useCart } from '@tofu/shop/hooks/use-cart'
import { useCartUpdate } from '@tofu/shop/hooks/use-cart-update'

export const useDiscountCheck = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { isLoggedIn } = useSession()

  const router = useRouter()
  const { data: cart, isFetchedAfterMount: isCartFetched } = useCart()

  const cartCode = cart?.discount_codes?.[0]?.code
  const hasCartCode = !isEmpty(cartCode)

  const { query, asPath } = router

  const paramQueryCode = query?.['discount']
  const hasParamQueryCode = !isEmpty(paramQueryCode) && isString(paramQueryCode)

  const removeDiscountQueryParam = () => {
    if (hasParamQueryCode) {
      router.replace(asPath, undefined, { shallow: true })
    }
  }

  const { mutateAsync: applyDiscount } = useCartUpdate()

  const { mutateAsync: checkDiscount } = useCartUpdate({
    // hide the success message as we only want to show an error, if its incorrect
    onSuccess: () => null
  })

  /**
   * Check the discount code from the query param
   */
  useEffect(() => {
    if (!isEmpty(paramQueryCode) && isString(paramQueryCode)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      applyDiscount({ discount_codes: [{ code: paramQueryCode }] }).then(() => {
        removeDiscountQueryParam()
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramQueryCode])

  /**
   * Checks discount on load.
   * This check runs on load, once, after the cart has been fetched.
   *
   * We can't add the cart into the dependency array as it could re-run after
   * they have applied a discount code, which we don't want.
   *
   * isCartFetched only runes once, on load.
   */
  useEffect(() => {
    if (isCartFetched && isLoggedIn && !hasParamQueryCode && hasCartCode) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      checkDiscount({ discount_codes: [{ code: cartCode }] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCartFetched])
}
