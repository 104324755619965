import PropTypes from 'prop-types'

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { client } from '@tofu/shared/data-access/prismic'
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { getPromoBannerBySlug } from '@tofu/shop/data-access/prismic'
import { StaticContentProvider } from '@tofu/shop/providers/static-content-provider'
import { getTrimmedProducts } from '@tofu/shared/data-access/products'
import { Box } from '@tofu/shared/ui/atoms/box'
import PageLayout from '@tofu/apps/shop/components/layout/page'
import Sections from '@tofu/apps/shop/components/slices'
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { DiscountCheck } from '@tofu/shop/ui/organisms/discount-check'
import { buildCarouselProducts } from '@tofu/shop/utils/build-carousel-products'

const Home = ({
  body,
  meta_description,
  meta_image,
  meta_title,
  promoBanner,
  carouselProducts
}) => {
  return (
    <StaticContentProvider
      promoBanner={promoBanner}
      carouselProducts={carouselProducts}
    >
      <PageLayout
        meta_description={meta_description}
        meta_image={meta_image}
        meta_title={meta_title}
        path='/'
        headerProps={{
          bg: 'brandYellow',
          borderBottom: '1px solid #E4C302'
        }}
      >
        <Box className='homepage'>
          <Sections sections={body} />
        </Box>
      </PageLayout>
      <DiscountCheck />
    </StaticContentProvider>
  )
}

export async function getStaticProps({ previewData = {} }) {
  const { ref } = previewData
  const page = await client.getByUID('single_page', 'homepage1', ref)

  const trimmedProducts = await getTrimmedProducts()
  const carouselProducts = buildCarouselProducts(trimmedProducts)

  const promoBanner = await getPromoBannerBySlug('app_page', 'home')

  if (!page) {
    const error = new Error('Content not found')
    error.statusCode = 404
    throw error
  } else {
    return {
      props: {
        ...page?.data,
        carouselProducts,
        promoBanner
      },
      revalidate: 60
    }
  }
}

Home.defaultProps = {
  meta_image: {}
}

Home.propTypes = {
  body: PropTypes.arrayOf(PropTypes.object).isRequired,
  meta_description: PropTypes.arrayOf(PropTypes.object).isRequired,
  meta_image: PropTypes.shape({
    Facebook: PropTypes.object,
    Twitter: PropTypes.object,
    url: PropTypes.string
  }),
  meta_title: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default Home
